import {IPorts} from "@app/interfaces/i-company";

export enum StorageKey {
  Session = 'Session',
  CurrentCompany = 'CurrentCompany',
  CurrentUserAssign = 'UserAssign',
  DocumentInMemories = 'DocumentInMemories',
  CurrentSession = 'CurrentSession',
  Shorcuts = 'Shorcuts',
  Ports = 'Ports',
  GuideTourConfig = 'GuideTourConfig',
  GuideTourButtonsConfig = 'GuideTourButtonsConfig',
  Permissions ='Permissions',
  Menu= 'Menu',
  LocalPrinter = 'LocalPrinter',
  PreviewDocumentMessage = 'PreviewDocumentMessage',
  ReportManager = 'ReportManager',
  IsUnauthorized = 'IsUnauthorized'
}
